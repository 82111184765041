import {
    apiCreate,
    makeRequest,
    toastError,
    toastWarning,
} from '@/components/helper';
import firebaseApp from '@/components/firebase/firebaseApp';
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';

// https://firebase.google.com/docs/auth/web/apple

export default async function appleLogin(toast, onFinished) {
    // create provider
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    // start login
    const auth = getAuth(firebaseApp);
    let loginData = await signInWithPopup(auth, provider).catch(error => {
        toastError(toast, error);
        console.error('start', error);
    });

    // user has canceledd
    if (!loginData) {
        return;
    }

    // Apple credential
    // console.log('loginData#####', loginData)

    // complete user table data
    let firstName = loginData._tokenResponse.firstName;
    let lastName = loginData._tokenResponse.lastName;
    if (!firstName) {
        let [emailname, domain] = loginData.user.email.split('@');
        firstName = emailname;
        lastName = emailname;
    }

    try {
        await apiCreate('/user/register-complete', {
            first_name: firstName,
            last_name: lastName,
        });
    } catch (error) {
        console.error('error', error);
        toastWarning(
            toast,
            'Bitte überprüfe unter Einstellungen deinen Namen.',
        );
    }

    // let result = await makeRequest('/user/register_complete', {
    //     formData: {
    //         firstName: firstName,
    //         lastName: lastName,
    //     },
    // });

    // warning to configure name
    // if (
    //     result.data.isAlreadyExists === false &&
    //     !loginData._tokenResponse.firstName
    // ) {
    //     toastWarning(
    //         toast,
    //         'Bitte überprüfe unter Einstellungen deinen Namen.',
    //     );
    // }
    await onFinished();
}
